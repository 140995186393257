/* $header
 ------------------------------------------*/
@use '@angular/material' as mat;
mat-toolbar {
  .toolbar-avatar {
    width: 40px;
    height: 40px;
    line-height: 24px;
  }
  .toolbar-avatar img{
    width: 40px;
    border-radius: 50%;
  }
  .notification-label {
    position: absolute;
    top: 0;
    left: 50%;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    background-color: mat.get-color-from-palette($warn);
    border: 4px solid mat.get-color-from-palette($warn);
    text-align: center;
  }
  &.main-header {
    padding: 0 8px;
    box-shadow: 0 1px 8px rgba(0,0,0,.3);
    z-index: 9;
    .branding {
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: auto 0;
      line-height: 50px;
      padding: 0 64px 0 16px;
    }
    .logo {
      background-image: url(/assets/images/logo.svg);
      width: 109px;
      height: 25px;
      -webkit-background-size: 109px 25px;
      background-size: 109px 25px;
      margin: auto;
    }
    .search-bar {
      .search-form {
        background: rgba(255,255,255,1);
        position: relative;
        border-radius: $border-radius-large;
        margin-right: $gutter;
        max-width: 800px;
        input {
          font-size: 1rem;
          z-index: 2;
          cursor: text;
          border: none;
          background: transparent;
          width: calc(100% - 65px); // Makes sure that input box does not go beyond the search form
          outline: 0;
        }
        .material-icons {
          color: rgba(mat.get-color-from-palette($foreground, base), 0.87);
          flex-shrink: 0;
        }
      }
    }
  }
}

// Specific height for mobile devices in portrait mode.
@media (max-width: 599px) and (orientation: portrait) {
  mat-toolbar {
    &.main-header {
      .branding {
        padding: 0 16px 0 16px;
      }
      .logo {
        width: 87px;
        height: 20px;
        -webkit-background-size: 87px 20px;
        background-size: 87px 20px;
      }
    }
  }
}

[dir="rtl"] {
  .main-header {
    .branding {
      padding: 0 16px 0 64px;
    }
    .search-bar {
      .search-form {
        .material-icons {
          left: auto;
          right: 10px;
        }
      }
    }
  }
}

/**********************************************************
 * CCP-2831: Header searchbar, logout and Broadspire logos
 **********************************************************/
// This keeps header component's height when screen is resized
@media (max-width: 599px) {
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 64px !important;
  }
}

/* Logos */
@media only screen and (max-width: 914.999px) {
	.main-header {
		.broadspire-logo-hd {
			.broadspire-logo-mobile {
				display: block;
			}
			.broadspire-logo {
				display: none;
			}
		}
		.search-bar {
			margin: 0px 32px 0px 40px;
		}
	}
	.log-out-wrapper {
		.log-out {
			display: none;
		}
	}
}

@media only screen and (min-width: 915px) {
	.main-header {
		.broadspire-logo-hd {
			.broadspire-logo-mobile {
				display: none;
			}
			.broadspire-logo {
				display: block;
			}
		}
		.log-out-wrapper {
			.log-out {
				display: block;
			}
		}
	}
}
/* END Logos */

// Hides Advanced search label
@media only screen and (max-width: 576px) {
	.cc-advanced-search-button {
		.advanced-search-label {
			display: none;
		}
	}
}