/* $error-pages
 ------------------------------------------*/
@use '@angular/material' as mat;

.session {
  position: relative;
  z-index: 4000;
  min-height: 100vh;
  @include flexbox;
  @include flex-direction(column);
}

.session-content {
  padding: 40px $gutter;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  @include flex(1 0 auto);
  @include flex-direction(column);
  min-height: 100%;
}

.session-wrapper {
  @include flex(none);
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.lockscreen-avatar {
  position: relative;
  display: block;
  margin: -75px auto 0 !important;
}

.lockscreen-wrapper {
  @include flex(none);
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}

.error-title {
  font-size: 150px;
  line-height: 1.2;
  font-weight: 900;
  display: inline-table;
  position: relative;
  background: mat.get-color-from-palette($primary);
  color: #fff;
  padding: 0 $gutter;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 0 $gutter;

  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: mat.get-color-from-palette($primary);
    border-width: 8px;
    margin-left: -8px;
  }
}

.error-subtitle {
  font-size: 34px;
  font-weight: 500;
  line-height: 38px;
  margin-top: 35px;
  color: #3A3A3A;
}

.error-message {
  font-size: 24px;
  line-height: 28px;
  margin-top: 22px;
  color: #535353;
  font-weight: 100;
}

.error-image {
  max-width: 492px;
  width: 100%;
}

.error-links {
  margin-top: 46px;
  text-decoration: underline;
  font-size: 16px;
  color: #79B700;
}

.error-code {
  font-weight: 600;
  color: #EFEFEF;
  font-size: 240px;
  line-height: 240px;
  letter-spacing: 0.48px;
}

.error-section {
  margin: 22px 13% 0px 13%;
  width: 74%;
}

.no-line-section {
  .craw-section-title {
    border: none !important;
  }
}

// #region Terms and Conditions
app-terms-and-conditions {
  --color-interactive-01: #0e57cc;
  --color-hover-primary: #126dff;

  overflow: auto;
  background-color: #f3f3f3;
  height: 100vh;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  justify-items: center;

  .terms-page-wrap {
    margin: 2.25rem 3rem 2rem;
    max-width: 796px;
    display: grid;
    grid-auto-rows: max-content;
    gap: 1rem;

    .cds-paragraph-terms {
      font-size: 1rem;
    }

    .craw-main-panel {
      background-color: var(--color-ui-background);
      padding: 3rem 1.5rem 0;
    }

    .craw-main-panel-footer {
      display: grid;
      gap: 2.5rem;
      grid-auto-rows: max-content;

    }
  }

  .footer-actions {
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-size: 1rem;
  }

  @media screen and (max-width: 640px) {
    .footer-actions {
      grid-auto-flow: row;
      grid-auto-columns: 1fr;
    }
  }

  .cds-button.-primary:hover {
    background-color: var(--color-hover-primary);
  }
}

/**
  CCP-29335:
  The selector specificity on _crawford.scss:9 is too high, 
  so we need to override it
*/
body app-terms-and-conditions a:not(.cds-nav-link):not(.cds-list-item-link) {
  color: #0e57cc !important;
  text-decoration: underline;
}

// #endregion