/* $cards
 ------------------------------------------*/
 @use 'sass:math';
 @use '@angular/material' as mat;
$mat-card-header-size: 40px !default;
body {
  .mat-card {
    padding: $gutter;
    margin: math.div($gutter, 3);
    border-radius: $border-radius-base;
    @include mat.elevation(1);
    color: rgba(mat.get-color-from-palette($foreground, base), 0.87);
    > :first-child {
      border-radius: $border-radius-base $border-radius-base 0 0;
    }
    > :last-child {
      border-radius: 0 0 $border-radius-base $border-radius-base;
    }
    .mat-card-image:first-child {
      margin-top: 0;
      border-radius: $border-radius-base $border-radius-base 0 0;
    }
    .mat-card-title {
      line-height: 1;
      font-size: 16px;
      font-weight: 400;
    }
    .mat-card-subtitle {
      line-height: 1;
      font-size: 13px;
    }
    .mat-card-image {
      width: calc(100% + #{$gutter * 2});
      margin: 0 (-$gutter) $gutter (-$gutter);
    }
    & > .mat-card-actions:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    hr {
      width: calc(100% + #{$gutter * 2});
      margin: math.div($gutter, 2) (-$gutter) math.div($gutter, 2) (-$gutter);
    }
    & > .mat-list {
      width: calc(100% + #{$gutter * 2});
      margin: 0 (-$gutter) 0 (-$gutter);
      padding-top: 0;
    }
    .content-full {
      width: calc(100% + #{$gutter * 2});
      margin: 0 (-$gutter) 0 (-$gutter);
    }
    .mat-card-content {
      margin-bottom: 0;
      position: relative;
    }
    [mat-fab-card-float] {
      top: -36px;
      position: absolute;
      right: 8px;
    }
    [mat-card-float-icon] {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -20px;
      width: 40px;
      height: 40px;
      .material-icons {
        font-size: 40px;
        opacity: .2;
        transform: rotate(-5deg);
      }
    }
    [mat-card-widget] {
      height: auto;
      display: flex;
      flex-direction: row;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-grid-row-align: center;
      align-items: center;
      -webkit-align-content: center;
      align-content: center;
      [mat-card-widget-title], p {
        margin: 0;
        padding: 0;
        line-height: $headings-line-height!important;
      }
    }
    .card-image-header {
      position: relative;
      background-size: cover;
      background-position: center bottom;
      background-repeat: no-repat;
      width: 100%;
      img {
        border-radius: $border-radius-base $border-radius-base 0 0;
        display: block;
        max-width: 100%;
      }
    }
    &.card-widget {
      .card-widget-content {
        display: flex;
        flex-direction: row;
        height: 40px;
        margin: -(math.div($gutter, 2)) 0 $gutter 0;
      }
    }
  }
}

body [dir="rtl"] {
  .mat-card {
    [matCardAvatar] {
      margin: $gutter $gutter 0 0;
    }
    [mat-fab-card-float] {
      right: auto;
      left: 8px;
    }
    [mat-card-float-icon] {
      right: auto;
      left: 15px;
    }
  }
}
