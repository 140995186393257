.cds-table-toolbar {
    position: relative;
    height: 0rem;

    &.-visible {
        height: 3rem;
    }
}

.cds-toolbar-content {
    /* this is for placing the toolbar info to the left side of its parent container and the toolbar action to the right of its parent container */
    /* Read more about flex: https://css-tricks.com/snippets/css/a-guide-to-flexbox */
    display: flex;
    justify-content: space-between;
    min-height: 3rem;
}

.cds-toolbar-info {
    display: flex;
    align-items: center;
    line-height: 1rem;
}

.cds-tool-bar-action{
    /* this is for displaying the injected buttons or filters in a row */
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3rem;
}

.cds-tool-bar-action {
  /* this is for giving all the children of the tool-bar-action container a 0.5rem left margin */
	> * {
		margin-left: 0.5rem;
		> * {
      margin-left: 0.5rem;
		}
	}
}


.table-rows-loading {
  overflow: hidden;
}

.table-rows-loading > * {
  margin-bottom: var(--spinnerMarginBottom);
}


.sticky {
  position: sticky;
  -webkit-position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.sticky:before,
.sticky:after {
    content: '';
    display: table;
}

app-table {
  .cds-dropdown .cds-dropdown-content {
    z-index: 2;
  }
}

app-table * {
  box-sizing: border-box;
}

.craw-table-container {
  overflow-x: auto;
  overflow-y: hidden;
  outline: 0;
}

.scrollable-table-container {
  overflow-y: auto;
  max-height: 300px;
}

.scrollable-table-container::-webkit-scrollbar-track {
  margin-top: 47px;
}

.craw-table-container, .action-bar-container, .footer-container, .row-detail-bottom-container {
    position: relative;
  }
  
  .craw-wrappable-content {
    &.wrap {
      white-space: normal;
    }
    
    &.nowrap {
      white-space: nowrap;
    }
  }

  .craw-table {
    min-width: 100%;
    border-collapse: collapse;
    position: relative;
  
    caption, tbody, tfoot, thead, tr, app-table-header, app-table-cell {
      padding: 0px;
      outline: 0px;
    }
  
    thead {
      height: 41px;
      text-align: left;

      th {
        box-shadow: inset 0 -2px 0 #979797;
      }

      app-table-header {
        display: table-cell;
        font-weight: bold;
        vertical-align: middle;
  
        .craw-table-header {
          height: 47px;
          display: inline-flex;
          align-items: center;
  
          &.craw-sortable {
            cursor: pointer;
            padding-right: 4px !important;
          }

          .craw-table-header-container {
            align-items: center;
            flex-direction: row;
            display: flex;
            width: 100%;
  
            .craw-table-header-text {
              @extend .craw-wrappable-content;
              margin-left: 12px;
              width: calc(100% - 15px);

              &.craw-table-header-not-sortable {
                width: 100% !important;
                margin-right: 20px;
              }
            }
      
            mat-icon {
              margin: 12px 0px 11px 0px;
              width: 15px;
            }
    
            .craw-table-header-pre-sort {
              visibility: hidden;
              color: #9D9D9D;
            }
    
            &:hover > .craw-table-header-pre-sort {
              visibility: visible;
            }
          }
        }
      }
    }

    tbody tr {
      height: 48px;
      box-sizing: border-box;
  
      app-table-cell {
        width: initial;
        height: 46px;
        display: table-cell;
        vertical-align: middle;

        .craw-cell-container {
          display: flex;
          align-items: center;
          height: 100%;
          margin: 0px 12px 0px 12px;
          justify-content: space-between;
  
          .craw-cell-content {
            @extend .craw-ellipsis;
            @extend .craw-wrappable-content;
            text-align: left;
            margin: 0;
            padding: 0;
            vertical-align: middle;
            font-size: 14px;
            line-height: 18px;
            max-height: 36px;
          }
        }
      }
      
      &.craw-table-row-selectable {
        &:hover,
        &:hover > app-table-cell > .craw-cell-container > .craw-cell-content::after,
        &.craw-table-row-selected,
        &.craw-table-row-selected > app-table-cell > .craw-cell-container > .craw-cell-content::after {
          cursor: pointer;
          background-color: #EFEFEF;
        }
    
        &.craw-table-row-selected {
          app-table-cell {
            color: #7BB804;
          }
        }
      }
  
      &:not(.craw-table-aggregate-row) {
        border-top: 2px solid #DFDFDF;
      }
  
      &:not(.craw-table-aggregate-row):first-child {
        border-top: 0px;
      }
  
      &.craw-table-aggregate-row {
        border-top: 2px solid #979797;
      }
    }
  }
  
  .craw-table-footer {
    display: flex;
    flex-wrap: wrap;
    border-top: 2px solid #979797;
    align-items: center;
    padding-top: 4px;
  
    .craw-table-footer-results {
      height: 48px;
      color: $color-text-01;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.17px;
      line-height: 20px;
      display: flex;
      align-items: center;
    }
  }

  .craw-table-nav-container {
    height: 40px;
    width: 100%;
    margin-top: 40px;
  }

  .craw-row-detail {
    margin-top: 40px;
    padding: 24px;
    background-color: #F8F8F8;

    &.display-navigator {
      margin-top: 20px;
    }
  }
  
  .craw-pagination {
    display: flex;
    flex-wrap: none;
    width: auto;
    height: 48px;
    align-items: center;
  
    .craw-pagination-page-size {
      width: auto;
      display: flex;
      align-items: center;

      .page-size-label {
        width: auto;
        color: $color-text-01;
        font-size: 16px;
        line-height: 20px;
        margin: auto;
      }
    
      .page-size-control {
        margin-left: 16px;
        margin-right: 12px;
        position: relative;
        text-align: left;
      }
    }
  
    .craw-pagination-navigation-control {
      width: 48px;
      height: 48px;
  
      mat-icon {
        padding: 12px;
      }
  
      &.enabled {
        color: $color-text-01;
        cursor: pointer;
  
        &:hover {
          color: #AEEA00;
        }
      }
  
      &.disabled {
        color: #9D9D9D;
        cursor: not-allowed;
      }
    }
  }