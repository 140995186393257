@use '@angular/material' as mat;
@import '@angular/material/theming';
$cc-primary-mat: mat.$blue-grey-palette;
$cc-primary: #607d8b;
$cc-primary-light: #8eacbb;
$cc-primary-dark: #34515e;

$cc-accent-mat: mat.$amber-palette;
$cc-accent: #ffc107;
$cc-accent-light: #fff350;
$cc-accent-dark: #c79100;

$cc-warn-mat: mat.$deep-orange-palette;
$cc-warn: #ff5722;
$cc-warn-light: #ff8a50;
$cc-warn-dark: #c41c00;

$dark-primary: mat.define-palette($cc-primary-mat);
$dark-accent:  mat.define-palette($cc-accent-mat, A200, A100, A400);
$dark-warn: mat.define-palette($cc-warn-mat);
$dark-theme: mat.define-dark-theme((
    color: (
        primary: $dark-primary,
        accent: $dark-accent,
        warn: $dark-warn
    )
));
$dark-background: map-get($dark-theme, background);
$dark-foreground: map-get($dark-theme, foreground);

.app-dark { 
    //backgrounds
    .cc-background-primary{
        background-color: $cc-primary !important;
        color: white !important;
    }
    .cc-background-primary-light{
        background-color: $cc-primary-light !important;
        color: $dark-text-color !important;
    }
    .cc-background-primary-dark{
        background-color: $cc-primary-dark !important;
        color: white !important;
    }
    .cc-background-accent{
        background-color: $cc-accent !important;
        color: $dark-text-color !important;
    }
    .cc-background-accent-light{
        background-color: $cc-accent-light !important;
        color: $dark-text-color !important;
    }
    .cc-background-accent-dark{
        background-color: $cc-accent-dark !important;
        color: white !important;
    }
    .cc-background-warn{
        background-color: $cc-warn !important;
        color: $dark-text-color !important;
    }
    .cc-background-warn-light{
        background-color: $cc-warn-light !important;
        color: $dark-text-color !important;
    }
    .cc-background-warn-dark{
        background-color: $cc-warn-dark !important;
        color: white !important;
    }

    //text color
    .cc-text-primary{
        color: $cc-primary !important;
    }
    .cc-text-primary-light{
        color: $cc-primary-light !important;
    }
    .cc-text-primary-dark{
        color: $cc-primary-dark !important;
    }
    .cc-text-accent{
        color: $cc-accent !important;
    }
    .cc-text-accent-light{
        color: $cc-accent-light !important;
    }
    .cc-text-accent-dark{
        color: $cc-accent-dark !important;
    }
    .cc-text-warn{
        color: $cc-warn !important;
    }
    .cc-text-warn-light{
        color: $cc-warn-light !important;
    }
    .cc-text-warn-dark{
        color: $cc-warn-dark !important;
    }

    //border color
    .cc-border-primary{
        border-color: $cc-primary !important;
    }
    .cc-border-primary-light{
        border-color: $cc-primary-light !important;
    }
    .cc-border-primary-dark{
        border-color: $cc-primary-dark !important;
    }
    .cc-border-accent{
        border-color: $cc-accent !important;
    }
    .cc-border-accent-light{
        border-color: $cc-accent-light !important;
    }
    .cc-border-accent-dark{
        border-color: $cc-accent-dark !important;
    }
    .cc-border-warn{
        border-color: $cc-warn !important;
    }
    .cc-border-warn-light{
        border-color: $cc-warn-light !important;
    }
    .cc-border-warn-dark{
        border-color: $cc-warn-dark !important;
    }   

    // Border-top
    .cc-border-top-primary {
        border-top-color: $cc-primary !important;
    }
}