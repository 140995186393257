@use '@angular/material' as mat;

/**
 * Ignore the error.
 *
 * This flag turns off @angular/material's duplication warning.
 * As it stands, @angular/material does not allow multiple themes
 * in an app.
 */
mat.$theme-ignore-duplication-warnings: true;

@import "client-themes/default";
@import "client-themes/default-dark";
@import "client-themes/sompo";
@import "client-themes/crawford";


// Default Theme
@include mat.all-legacy-component-themes($default-theme);

// Dark Theme
.app-dark {  
  @include mat.all-legacy-component-themes($dark-theme);
}

// Client Themes
.sompo {  
  @include mat.all-legacy-component-themes($sompo-theme);
}
.crawford {    
  @include mat.all-legacy-component-themes($crawford-theme);
}

