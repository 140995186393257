.craw-currency-range {
  &.-invalid {
    .cds-input-container {
      color: $color-ui-04;
      background-color: rgba($color-error, 0.15);
      border-color: $color-error;

      &.-active {
        box-shadow: 0px 0px 0px 3px $color-focus-error;
      }
    }
  }
}