// Component library variables
@import "../../../node_modules/@cds/core/cds.min";

@import 'scss/fonts';
@import "scss/material.variables";
@import "scss/material.themes";
@import "scss/mixins/bi-app/bi-app-ltr";
// Core Variables and Mixins
@import "scss/app.variables";
@import "scss/mixins";
// Core Styles
@import "scss/core";
@import "scss/material";
@import "scss/utilities/utilities";

// Component library styles
@import "../../component-library/core/component-library";

// Rule added to mitigate the hiding of the expandable container. This was a discrepancy between CCP, DC, & CDS.
// What is happening, is the rule is preventing Expandable containers "Read More" link to not show, this rule only affects
// that functionality.
.dynamic-panel.hide {
  display: block !important;
}

// TODO: verify that these are the correct colors
:root,
.cds-dark-mode {
  .-selected>.cds-nav-link,
  .-selected>.cds-nav-link::after {
    --color-interactive-01: #9aca3c !important;
  }
}