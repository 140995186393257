//*******Custom styles - Prefix with cc- (Client Connect)*******
@import "../material.themes";

$mat-toolbar-height-desktop: 64px !default;
$mat-sidenav-footer-height: 48px !default;
$cc-sidenav-width-open: 240px !default;
$cc-sidenav-width-closed: 48px !default;

.cc-table-link {
    font-weight: bold;
}
.cc-search-result-toolbar{
    margin:16px 0px 3px 0px;
    color: rgba(0, 0, 0, 0.54);
    .tools {
        font-size: 14px;
        display: inline;
    }
    .mat-button{
        padding: 0 8px !important;
    }
}

.cc-recordCount {
    padding: .7em .2em;
}

.cc-mat-toolbar{
    height: 40px !important;
}

app-shared-dynamic-panel {
    mat-checkbox {
        margin-left: 15px !important;
        label {
           width: auto !important;
        }
    }
}

.cc-advanced-search-dialog-backdrop {
    opacity: 0;
}

.cc-column-ordering-row {
    @extend .cc-border-util-light;
    border: 1px solid;
    border-radius: 4px;
    height: 40px;
    margin-bottom: 12px;
    line-height: 40px;
    background-color: $color-field-01;
    position: relative;

    &.first {
        margin-top: 12px;
    }

    &:hover {
        @extend .cc-border-accent-dark;
    }

    &.active {
        @extend .cc-border-accent-dark;
        @extend .cc-text-accent-dark;
        z-index: 999 !important;
    }

    .label {
        padding: 0px 20px 0px 20px;
        float: left;
    }

    .controls {
        float: right;
    }
}

//*******Overrides*******
.headerLogo {
    font-size: 26px;
    line-height: 1.2;
}
.notification-label {
    background-color: mat-color($accent) !important;
    border: 4px solid mat-color($accent) !important;
}

.mat-header-cell{
    text-align: left !important;
}

.sidebar-panel .craw-sideBarMenu {
    width: 15rem;
    box-sizing: border-box;
}

.collapsed-sidebar {
  .craw-sidebar-panel:not(.craw-sidebar-panel-hover) {
    .craw-menu-widget {
        width: auto;
        height: $sidebar-width;
        padding: 9px !important;

        .craw-widget-section {
            width: $sidebar-width;
            text-align: center;
            transform-origin: top left;
            transform: rotate(-90deg) translate(-100%);
            margin: 0px;
        }

        .craw-widget-hidable {
            display: none;
        }
    }

    .craw-sideBarFooter {
      width: $cc-sidenav-width-closed;

      button.mat-icon-button {
        width: initial;
        margin: 12px 4px 12px 0;
      }

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        button.mat-icon-button {
            margin : auto;
        }
     }
    }

    .craw-sideBarMenu {
        max-height: calc(100vh - #{$mat-toolbar-height-desktop} - #{$mat-sidenav-footer-height});
        max-height: calc((var(--vertical-height, 1vh) * 100) - #{$mat-toolbar-height-desktop} - #{$mat-sidenav-footer-height});
        max-height: calc(100dvh - #{$mat-toolbar-height-desktop} - #{$mat-sidenav-footer-height});
    }

    .craw-sideBarFooterTools {
      display: flex;
      flex-direction: column;
      height: initial;
      padding: initial;
    }
  }
}

/* Loading Bar / Spinner */
$cc-loading-spinner-width: 48px;
$cc-loading-spinner-height: 48px;

$cc-loading-spinner-top: calc(#{$mat-toolbar-height-desktop} + 16px);
$cc-loading-spinner-left: calc(100vw - #{$cc-loading-spinner-width} - 24px);

.cc-loading-bar {
    #loading-bar {
        .bar {
            height: 4px;
            color: white !important;
            background: white !important;
        }
    }
    #loading-bar-spinner {
        position: fixed;
        top: $cc-loading-spinner-top !important;
        left: $cc-loading-spinner-left !important;
        color: rgb(54, 54, 59) !important;

        .spinner-icon {
            width: $cc-loading-spinner-width !important;
            height: $cc-loading-spinner-height !important;
            border-width: 4px !important;
            animation-duration: 750ms !important;
        }
    }
}

/* Error / Session */
.error-title {
    @extend .cc-background-primary;
    &:after {
        @extend .cc-border-top-primary;
    }
}
