@use '@angular/material' as mat;
@import '@angular/material/theming';
$cc-primary-mat: mat.$red-palette;
$cc-primary: #d32f2f;
$cc-primary-light: #ff6659;
$cc-primary-dark: #9a0007;

$cc-accent-mat: mat.$cyan-palette;
$cc-accent: #00bcd4;
$cc-accent-light: #62efff;
$cc-accent-dark: #008ba3;

$cc-warn-mat: mat.$amber-palette;
$cc-warn: #ffa000;
$cc-warn-light: #ffd149;
$cc-warn-dark: #c67100;

$primary: mat.define-palette($cc-primary-mat, 600, 400, 900);
$accent:  mat.define-palette($cc-accent-mat, A700, A400);
$warn: mat.define-palette($cc-warn-mat);
$sompo-theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warn: $warn
    )
));
$background: map-get($sompo-theme, background);
$foreground: map-get($sompo-theme, foreground);

$dark-text-color:  rgb(37, 37, 37);

.sompo {  

    //backgrounds
    .cc-background-primary{
        background-color: $cc-primary !important;
        color: white !important;
    }
    .cc-background-primary-light{
        background-color: $cc-primary-light !important;
        color: $dark-text-color !important;
    }
    .cc-background-primary-dark{
        background-color: $cc-primary-dark !important;
        color: white !important;
    }
    .cc-background-accent{
        background-color: $cc-accent !important;
        color: $dark-text-color !important;
    }
    .cc-background-accent-light{
        background-color: $cc-accent-light !important;
        color: $dark-text-color !important;
    }
    .cc-background-accent-dark{
        background-color: $cc-accent-dark !important;
        color: white !important;
    }
    .cc-background-warn{
        background-color: $cc-warn !important;
        color: $dark-text-color !important;
    }
    .cc-background-warn-light{
        background-color: $cc-warn-light !important;
        color: $dark-text-color !important;
    }
    .cc-background-warn-dark{
        background-color: $cc-warn-dark !important;
        color: white !important;
    }

    //text color
    .cc-text-primary{
        color: $cc-primary !important;
    }
    .cc-text-primary-light{
        color: $cc-primary-light !important;
    }
    .cc-text-primary-dark{
        color: $cc-primary-dark !important;
    }
    .cc-text-accent{
        color: $cc-accent !important;
    }
    .cc-text-accent-light{
        color: $cc-accent-light !important;
    }
    .cc-text-accent-dark{
        color: $cc-accent-dark !important;
    }
    .cc-text-warn{
        color: $cc-warn !important;
    }
    .cc-text-warn-light{
        color: $cc-warn-light !important;
    }
    .cc-text-warn-dark{
        color: $cc-warn-dark !important;
    }

    //border color
    .cc-border-primary{
        border-color: $cc-primary !important;
    }
    .cc-border-primary-light{
        border-color: $cc-primary-light !important;
    }
    .cc-border-primary-dark{
        border-color: $cc-primary-dark !important;
    }
    .cc-border-accent{
        border-color: $cc-accent !important;
    }
    .cc-border-accent-light{
        border-color: $cc-accent-light !important;
    }
    .cc-border-accent-dark{
        border-color: $cc-accent-dark !important;
    }
    .cc-border-warn{
        border-color: $cc-warn !important;
    }
    .cc-border-warn-light{
        border-color: $cc-warn-light !important;
    }
    .cc-border-warn-dark{
        border-color: $cc-warn-dark !important;
    }       

    // Border-top
    .cc-border-top-primary {
        border-top-color: $cc-primary !important;
    }
}    