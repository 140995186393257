@import "../material.themes";

$mat-footer-height: 15px !default;
$mat-toolbar-height-desktop: 64px !default;
$mat-sidenav-footer-height: 48px !default;
$cc-sidenav-width-open: 240px !default;
$cc-sidenav-width-closed: 48px !default;

body a:not(.cds-nav-link):not(.cds-list-item-link){
  @extend .cc-text-accent-dark;
}

h1{
  @extend .craw-text-xl;
  font-weight: 300 !important;
  letter-spacing: -0.3px;
  line-height: 40px !important;
}

h2{
  @extend .craw-text-lg;
  @extend .cc-text-primary-dark;
  font-weight: 500 !important;
  letter-spacing: 0.25px;
  line-height: 24px !important;
}

.craw-text-xs { font-size: 10px !important; }
.craw-text-sm { font-size: 12px !important; }
.craw-text-md { font-size: 14px !important; }
.craw-text-df { font-size: 16px !important; }
.craw-text-lg { font-size: 20px !important; }
.craw-text-xl { font-size: 36px !important; }
.craw-text-xxl { font-size: 60px !important; }

.craw-text-light { font-weight: 300 !important; }
.craw-text-medium { font-weight: 500; }
.craw-bold {
  font-weight: bold;
}

.craw-section-title {
  @extend h2;
  color: $color-text-01 !important;
  letter-spacing: .25px !important;
  border-bottom: 2px solid #DFDFDF !important;
  margin-bottom: 17px !important;
}

.craw-page-title {
  @extend h1;
  color: $color-text-01 !important;
  letter-spacing: -0.3px !important;
  margin-bottom: 21px !important;
}

//*****  SCROLLBAR  ***********************************//
/* width */
::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(242, 242, 242, 0.7);
  border-left: 1px solid rgba(148, 148, 148, 0.25);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(128, 128, 128, 0.6);
  border: 3px solid transparent;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(120, 121, 124, 0.7);
  border: 3px solid transparent;
  background-clip: padding-box;
}

/* Dark mode scroll bar*/
.cds-dark-mode::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.4);
  border-left: 1px solid rgba(0, 0, 0, 0.25);
}

.cds-dark-mode::-webkit-scrollbar-thumb:hover {
  background: rgba(240, 240, 240, 0.6);
  border: 3px solid transparent;
  background-clip: padding-box;
}

.cds-dark-mode::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid transparent;
  background-clip: padding-box;
}
.cds-modal-content {
  margin-left: -4px;
  margin-right: -4px;
  padding-left: 4px;
  padding-right: 4px;
}

/* The emerging W3C standard
that is currently Firefox-only */
* {
  scrollbar-width: auto;
  scrollbar-color: #78797C $color-ui-02;
}

.cds-dark-mode {
  scrollbar-width: auto;
  scrollbar-color: #919191 rgba(0, 0, 0, 0.4);
}
//*****  END SCROLLBAR  *******************************//

//*****  NAVIGATION  ***********************************//
/* fix for padding in collapsed mode*/
.cds-nav-link {
  &.-with-icon {
    padding: 0 0.75rem 0 0.75rem;
  }
  padding: 0.25rem 0.75rem 0 0.75rem;

  .cds-tooltip {
    z-index: unset !important;

    .cds-tooltip-trigger.-visible ~ .cds-tooltip-content {
      z-index: 6 !important;
    }
  }
}

.cds-nav-item {
  &.-selected i {
    color: var(--color-interactive-01);
  }
}
//*****  END NAVIGATION  ******************************//

//*****  LAYOUT  ***********************************//
.hide-content {
  display: none;
}

.craw-page-wrap {
  min-height: calc(100vh - #{$mat-toolbar-height-desktop} - #{$mat-footer-height});
  min-height: calc((var(--vertical-height, 1vh) * 100) - #{$mat-toolbar-height-desktop} - #{$mat-footer-height}); // Fallback if new viewport units are not supported
  min-height: calc(100dvh - #{$mat-toolbar-height-desktop} - #{$mat-footer-height}); // Utilizing the new viewport units - e.g. dvh, svh, lvh. See: https://www.w3.org/TR/css-values-4/#viewport-relative-lengths
  padding: 2.5rem !important;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.dashboard .craw-page-wrap {
  padding: 2.25rem 2.5rem !important;
}

.craw-main-panel {
  flex: auto;
  flex-shrink: 0;
}

.craw-sideBarFooter {
  @extend .cc-border-primary-dark;
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  width: 100%;
  height: fit-content !important;
  text-align: right;
  flex: none;
  bottom: 0px;
  position: absolute;
}

.craw-sideBarFooterTools {
  padding: 12px;

  button.mat-icon-button {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}

.craw-sideBarMenu {
  position: relative;
  max-height: calc(100vh - #{$mat-toolbar-height-desktop} - #{$mat-sidenav-footer-height});
  max-height: calc((var(--vertical-height, 1vh) * 100) - #{$mat-toolbar-height-desktop} - #{$mat-sidenav-footer-height});
  max-height: calc(100dvh - #{$mat-toolbar-height-desktop} - #{$mat-sidenav-footer-height});
}

div.craw-sideBarMenu::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.4);
  border-left: 1px solid rgba(0, 0, 0, 0.25);
}

div.craw-sideBarMenu::-webkit-scrollbar-thumb:hover {
  background: rgba(240, 240, 240, 0.6);
  border: 3px solid transparent;
  background-clip: padding-box;
}

div.craw-sideBarMenu::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid transparent;
  background-clip: padding-box;
}

div.craw-sideBarMenu {
  scrollbar-width: auto;
  scrollbar-color: #919191 rgba(0, 0, 0, 0.4);
}
//*****  END LAYOUT  *******************************//

//*****  GRIDS  ***********************************//
.craw-explicit-grid {
  display:grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;
  column-gap: 24px;
}

.craw-sm-item-row, .craw-md-item-row, .craw-lg-item-row {
  display: grid;
  grid-column-gap: 24px;
  column-gap: 24px;
}

.craw-sm-item-row {
  grid-template-columns: repeat(auto-fit,minmax(140px, 1fr));
}

.craw-md-item-row {
  grid-template-columns: repeat(auto-fit,minmax(224px, 1fr));
}

.craw-lg-item-row {
  grid-template-columns: repeat(auto-fit,minmax(340px, 1fr));
}

.craw-col-span-0 {
  display: none;
}

.craw-col-span-2 {
  grid-column: auto / span 2;
}

.craw-col-span-4 {
  grid-column: auto / span 4;
}

.craw-col-span-6 {
  grid-column: auto / span 6;
}

.craw-col-span-8 {
  grid-column: auto / span 8;
}

.craw-col-span-12 {
  grid-column: auto / span 12;
}

.craw-main-panel-footer {
  @extend .craw-text-md;
  text-align: center;
  margin: 0px!important;
  flex-shrink: 0;
}

.craw-data-field {
  @extend .craw-explicit-grid;

  .craw-data-field-label, .craw-data-field-content {
    @extend .craw-col-span-6;
  }

  .craw-data-field-content-fullspan {
    @extend .craw-col-span-12;
    max-width: 100%;
  }
}

mat-grid-tile{
  background-color: aqua
}

//*****  END GRIDS  ********************************//


//*****  ATOMS: FORMS/LABELS/VALUES  ***********************************//
.craw-field {
  @extend .craw-text-df;
  @extend .cc-text-primary;
  color: $color-text-01;
  line-height: 20px;
  margin-bottom: 12px;
  vertical-align: top;
  display: inline-block;
}

.craw-data-field .craw-data-field-label label {
  margin-bottom: 12px;
  white-space: normal;
}

.craw-field-content  {
  @extend .craw-field;
  text-align: left;
  font-weight: 400;
  word-break: break-word;
}

p.craw-text-group {
  @extend .craw-text-light;
  @extend .craw-text-md;
  line-height: 16px;
  margin: 0;
  word-wrap: break-word;
  word-break: break-word;

  .craw-field-content {
    @extend .craw-text-md;
    margin-bottom: 0px;
    line-height: 16px;
  }
}

.craw-field-currency {
  text-align: right;
  font-weight: 400;
}

.craw-field-center {
  min-width: 15em;
  text-align: center;
  font-weight: 400;
  display: inline-block;
}

.craw-field-textarea {
  width: 100%;
}

.craw-subfield {
  .cds-label,
  .craw-field-content,
  .no-data {
    @extend .craw-field;
    font-weight: 300;
    font-size: 14px !important;
    line-height: 16px !important;
    margin-top: -10px;
  }
  .craw-data-field-label, .craw-data-field {
    line-height: 16px;
  }
}

.craw-form-control {
  width: 100%;
  font-size: 16px;
  line-height: 24px;

  .mat-form-field-infix {
    border-top-width: 0px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0px;

    .mat-form-field-underline {
      background-color: #BBBBBB;
      bottom: 7px;
    }
  }
}

.cds-alert {
  align-items: center;
  padding: unset !important;
  .cds-alert-icon {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .cds-alert-content {
    align-items: center;
    .cds-alert-title, .cds-alert-body {
      margin-top: unset !important;
    }
    .cds-alert-body {
      a {
        color: inherit !important;
        text-decoration: underline;
      }
    }
  }
  .cds-control-block-content {
    line-height: 2.5rem !important;
  }
}

%craw-form-control-focused {
  .mat-form-field-underline, .mat-form-field-ripple {
    height: 2px !important;
    background-color: #AEEA00 !important;
  }
}

%craw-form-control-hover {
  @extend %craw-form-control-focused;
  .mat-select-value, .mat-select-arrow, mat-label {
    color: #AEEA00 !important;
  }
}

// This applies to non-IE browsers
@supports not (-ms-high-contrast: none) {
  .craw-form-field {
    display: grid;
    grid-column-gap: 12px;
    width: 100%;
    grid-template-columns: 196px 1fr;
    padding:4px 0px 4px 0px;
  }
}

// Add margin to bottom of last form-field in Advanced Search
app-form-field {
  &:last-of-type > .craw-form-field {
    margin-bottom: 24px;
  }
}

.craw-input-label {
  padding-top: 10px;
  .cds-label {
    font-size: 1rem;
  }
}

.craw-input {
  display: flex;

  .craw-input-operator {
    flex: 1 1 50%;
    margin-right: 24px;
  }

  .craw-input-field {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .craw-input-atom {
    flex: 3;
  }

  .craw-error-message {
    display: block;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    color: $color-error;
  }
}

.craw-input-atom {
  width: 100%;
}

app-currency-input * {
  min-width: 10.5rem;
}

.craw-second-currency {
  margin-top: 8px;
}

.craw-second-currency * {
  max-width: 320px;
}

.craw-ellipsis {
  overflow: hidden;
  position: relative;
  text-align: left;
  margin-right: -0.5em !important;
  padding-right: 1em !important;

  &::before {
    content: '...';
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
  }
  &::after {
    content: '';
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    width: 1em;
    /* set width and height */
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
}

.craw-list-item-group{
  margin-bottom: 40px;
}

// CCP-11265 fix for curreny range input alignment
.craw-second-currency {
  .cds-input-container {
    margin-top: 0;
    display: inline-flex;
  }
}
//*****  END ATOMS: FORMS/LABELS/VALUES  *******************************//

//*****  ORGANISMS: SECTIONS  *******************************//

.craw-section-content {
  @extend .craw-lg-item-row;
  margin-bottom: 28px;
}

.craw-section-small-content {
  .craw-section-content {
    @extend .craw-sm-item-row;
    margin-bottom: 0px;
  }
}

.craw-section-small-content:has(.vertical-list) .craw-section-content {
  margin-bottom: 2.5rem;
}

.mat-dialog-content {
  .dynamic-panel {
    :last-child.craw-panel-row {
      .craw-section-content {
        margin-bottom: 0px;

        div {
          :last-child .craw-data-field {
            .craw-field-label,
            .craw-field-content,
            .craw-field-currency,
            .craw-field-center,
            .craw-field-textarea {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

//*****  END ORGANISMS: SECTIONS  *******************************//

//*****  TEMPLATES: DYNAMIC PANEL  *******************************//

.craw-panel-row {
  @extend .craw-lg-item-row;
}

//*****  END TEMPLATES: DYNAMIC PANEL  *******************************//

//*****  WIDGETS  ***********************************//
.craw-widget-section{
  margin-top: 8px;
  color: #DFDFDF;

  div{
    line-height: 16px;
  }
}

.craw-widget-field-label {
  @extend .craw-text-sm;
  display: inline;
}

.craw-widget-field-text {
  @extend .craw-widget-field-label;
  font-weight: 600;
}

.craw-menu-widget {
  @extend .cc-border-primary-dark;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  padding: 16px;
  font-family: Roboto, Tahoma, sans-serif;
  overflow-wrap: break-word;
  .status-section {
    font-size: 14px;
    line-height: 16px;
  }

  .widget-claimant-name {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }

  .widget-claim-number {
    color: #DFDFDF;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
  }

  .craw-widget-claimant-sec {
    margin-top: 0px !important;
  }

  .field-label-key {
    color: #DFDFDF;
    font-size: 12px;
    line-height: 16px;
  }

  .field-text-value {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
  }

  .section-info {
    color: #DFDFDF !important;
  }

  .adjuster-name {
    font-size: 14px !important;
    font-weight: bold;
    line-height: 16px;
  }

  .adjuster-email {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
  }
}

.craw-menu-widget-tooltip {
  max-width: unset !important;
}

//*****  END WIDGETS  *******************************//

/* Override Template & Material Styles *****************************/
.main-header{
  @extend .cc-background-primary-dark;
  padding: 0px 20px 0px 16px !important;
}

mat-sidenav.craw-sidebar-panel{
  @extend .cc-background-primary;

  app-menu {
    background-color: lightblue;
    mat-nav-list {
      margin: 0px !important;
      padding: 0px !important;

      mat-list-item {
        @extend .cc-background-primary;
        @extend .cc-border-primary-dark;
        margin: 0px !important;
        padding: 0px !important;
        width: auto !important;
        height: 48px !important;
        border-width: 0px 0px 1px 0px;
        border-style: solid;
        box-sizing: border-box;

        div.mat-list-item-content {
          margin: 0px !important;
          padding: 0px !important;
          max-height: 46px !important;

          a {
            margin: 0px !important;
            padding-top: 12px !important;
            padding-left: 12px !important;
            padding-right: 16px !important;
            padding-bottom: 12px !important;
            width: auto !important;

            mat-icon {
              margin: 0px !important;
              padding: 0px !important;
              width: 24px !important;
              height: 24px !important;
              font-size: 24px !important;
              line-height: 24px !important;
              text-align: center !important;
            }

            span {
              margin: 0px !important;
              margin-left: 12px !important;
              width: auto !important;
              height: 16px !important;
              font-size: 14px !important;
              font-family: Roboto, Tahoma, sans-serif !important;
              line-height: 16px !important;
            }
          }
        }
      }
      //Nav list item that is currently selected/opened
      .mat-list-item.open{
        @extend .cc-border-accent;
        border-width: 0px 0px 2px 0px;
        border-style: solid;
        cursor: default;
      }
      //Nav list content item that is currently selected or mouse over/hover
      .mat-list-item.open > .mat-list-item-content, .mat-list-item.open:hover > .mat-list-item-content {
        a{
          @extend .cc-background-primary-light;
          @extend .cc-text-accent;
          pointer-events: none;
        }
      }

      .mat-list-item:hover > .mat-list-item-content {
        a {
          @extend .cc-background-primary-light;
          color: white !important;
        }
      }

      //Nav list item not selected and not hovered
      .mat-list-item:not(:hover) > .mat-list-item-content {
        a{
          @extend .cc-background-primary;
        }
      }
    }
  }
}

.mat-drawer-container {
  background-color: #ffffff !important;
}

.mat-button {
  @extend .craw-text-md;
  @extend .cc-text-util;
  @extend .cc-border-util;
  height: 36px !important;
  font-weight: bold !important;
  line-height: 36px !important;
  text-align: center !important;
  border: 1px solid !important;
  border-radius: 4px !important;
  margin: 3px 5px !important;
}

button.active, .mat-button:hover{
  @extend .craw-text-md;
  @extend .cc-background-accent-dark;
  height: 36px;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  border: 0px;
}

.search-bar {
  margin: 0px 32px 0px 84px;

  .search-form {
    @extend .cc-text-primary;
    display: flex;
    max-width: none !important;
    margin-right: 0px !important;
    border-radius: 16px !important;
    height: 2rem;

    > input {
      line-height: 24px;
      padding: .25rem 0rem;
    }

    > .material-icons {
      margin: auto 0px auto 12px;
      vertical-align: middle;
    }

    > .cc-advanced-search-button {
      @extend .cc-text-primary-dark;
      font-family: Roboto, Tahoma, sans-serif !important;
      font-size: 14px !important;
      color: rgba(0, 0, 0, 0.87);
      margin-right: 9px;

      .material-icons {
        vertical-align: middle;
      }
    }
  }

  .cc-advanced-search-dropdown mat-form-field {
    input {
      padding: 0px !important;
    }

    label {
      padding-left: 30px !important;
    }
  }
}

mat-optgroup.craw-search-optgroup {

  .mat-optgroup-label {
    height: 16px;
    padding: 12px 16px !important;
    font-size: 12px;
    line-height: 16px;
    color: $color-ui-05;
  }
  mat-option {
    height: 48px;
    padding: 12px 16px !important;
    line-height: 26px;
    font-size: 14px;
    color: $color-text-01;
    border-top: 1px solid $color-ui-03;
  }
  mat-option:last-of-type {
    border-bottom: 1px solid $color-ui-03;
  }

  .mat-option.mat-active {
    background: $color-ui-03 !important;
  }

  mat-option:hover {
    background: $color-hover-ui !important;
  }
  .suggestion-row{
    display: flex;
    justify-content: space-between;
    width: 100%;

    .suggestion-icon {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.29px;
      margin-right: 16px;
      line-height: 20px;
    }
    .suggestion-label{
      overflow: hidden;
      text-overflow: ellipsis;

      .suggestion-label-item {
        margin-right: 16px;
      }
    }

    .suggestion-meta-label {
      color: $color-ui-05;
      font-size: 14px;
      overflow:hidden;
      flex-shrink: 999;
    }
  }
}

.log-out-wrapper {
  display: flex;

  width: auto;
  height: 24px;
  margin: 20px 0px;

  .log-out {
    width: 56px;
    height: 16px;
    padding: 4px 0px;
    margin: 0px 0px 0px 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
}

.log-out-wrapper:hover {
  cursor: pointer;
}

.log-out-wrapper:active {
  @extend .cc-text-accent;
  @extend .log-out-wrapper, :hover;
}

.craw-dialog-backdrop {
  background-color: rgba(17,17,19,0.78);
}

.craw-dialog {
  .mat-dialog-container {
    padding: 32px !important;
  }

  .mat-dialog-content {
    margin: 0px -4px 24px -4px !important;
    padding: 0px 4px !important;
  }

  .mat-dialog-actions {
    margin-bottom: 0px !important;
    padding: 0px !important;
    > * {
      margin-left: 0.5rem;
    }
  }
}

.mat-tooltip {
  font-size: 14px !important;
  font-family: Roboto, Tahoma, sans-serif !important;
}

.not-collapsed-sidebar {
  .mat-icon {
    pointer-events: none;
  }

}

.dashboard-container {
  background-color: white !important;
  padding: 1.5rem;
  margin-bottom: 1.5em;
}

.dashboard {
  background-color: #F3F3F3 !important;
}

.dashboard-container .cds-table-row.-selectable.-selected td,
app-claim-results .cds-table-row.-selectable.-selected td {
  color: rgb(89,89, 89);
}

// Note:
//  This matches the html strcture of claim notes that have
//  been converted to html in the RiskTechClaimRetrieval-sapi.
//
//  I have not found a way to configure the inline styles that
//  code generates. So instead, I am overriding the styles here.
//
//  We may want some way for the dynamic panel json for notes
//  to specify a class on the outer paragraph so that these can
//  be further restricted to just that context.
//                    - Brian Rowlett, 2019-11-22
.craw-field-content {
  .default {
    *[style] {
      font-size: 16px !important;
      font-family: Roboto, Tahoma, sans-serif !important;
    }
  }
  p.default {
    margin-top:0px;
  }
  p.default:last-of-type {
    margin-bottom: 0px !important;
  }
}

app-claim-results .cds-table-toolbar.-visible {
  height: auto;

  .craw-field-link {
    font-size: 14px !important;
  }
}

//***** ADVANCED SEARCH ***************************************//
// TODO: Consider redesigning the Advanced Search fields
.cc-advanced-search-dialog {
  max-width: 805px;       // _main-header.scss .search-bar .search-form { max-width }
  margin-top: 56px !important;
  margin-right: 133px !important;
  padding: 0px 20px !important;

  @media screen and (max-width: 914px) {
    max-width: unset !important;
    width: 100% !important;
    margin: 56px auto auto !important;
  }

  .mat-dialog-container {
    overflow: hidden !important;
  }
}

.cc-advanced-search-dialog-quick-reports {
  max-width: 805px;       // _main-header.scss .search-bar .search-form { max-width }
  margin-top: 56px !important;
  margin-right: 280px !important;
  padding: 0px 20px !important;

  @media screen and (max-width: 914px) {
    max-width: unset !important;
    width: 100% !important;
    margin: 56px auto auto !important;
  }

  .mat-dialog-container {
    overflow: hidden !important;
  }
}

// CCP-4002: Input fields are shrinking too little than their expected size
@media screen and (max-width: 679px) {
  .app-clu .cds-input {
    width: unset !important;
  }
}

// bugfix for CDS upgrade v0.3.5 to v0.6.1
// CCP-11265 fill cds dropdown container width
.cds-dropdown-toggle {
  width: 100%;
  .cds-button {
    grid-template-columns: 1fr;
    width: 100%;
    text-align: left;
  }
}


//*****  INTERNET EXPLORER FIX  *******************************//

@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ie-craw-explicit-grid {        // explicit grid e.g. craw-data-field / craw-form-field / craw-panel-row
    width: 100%;
    float:left;
    display: block;

    > * {
      float:left;
      display: block;
      margin-right: 12px;     // grid-column-gap
      margin-left: 12px;
      flex: auto;
      height: 100%;
      width: 100%;

      &:first-child {
        margin-left: 0;     // no grid-column-gap for last child
      }

      &:last-child {
        margin-right: 0;     // no grid-column-gap for last child
      }
    }

    .craw-col-span-0 {
      display: none;
    }

    .craw-col-span-2 {
      width: calc(16% - 12px);
    }

    .craw-col-span-4 {
      width: calc(33% - 12px);
    }

    .craw-col-span-6 {        // e.g. craw-data-field-label / craw-date-field-content
      width: calc(50% - 12px);
    }

    .craw-col-span-8 {
      width: calc(66% - 12px);
    }

    .craw-col-span-12 {
      width: 100%;
    }
  }

  .ie-craw-implicit-grid {     // implicit grid e.g. craw-section-content
    width: 100%;
    float:left;
    display: block;
    margin-left: -12px;
    margin-right: -12px;

    > * {
      width:calc(100% - 12px);
      float:left;
      display: block;
      margin-right: 12px;     // grid-column-gap
      margin-left: 12px;
      flex: auto;
      height: 100%;

      &:only-child {
        width: 100%;
      }
    }
  }

  .craw-section-content  > [classes='craw-field-content']{
    width:150px;
    }

  .craw-explicit-grid {
    @extend .ie-craw-explicit-grid;
  }

  .craw-lg-item-row, .craw-sm-item-row, .craw-md-item-row {
    @extend .ie-craw-implicit-grid;
  }

  .craw-sm-item-row > div {
    min-width: 140px;         // grid minmax
  }

  .craw-md-item-row > div {
    min-width: 224px;         // grid minmax
  }

  .craw-lg-item-row > div {
    min-width: 340px;         // grid minmax
  }

  // Sidenav

  .collapsed-sidebar {
    mat-sidenav.sidebar-panel {
      .mat-list-item > .mat-list-item-content > a > span:not(.menu-badge) {
        display: none !important;
      }
    }
  }

  // Menu Widgets
  .craw-menu-widget-tooltip {
    // Note:
    //  max-width: unset !important; is not working
    //  in Internet Explorer, however setting some large
    //  value does. Hopefully 1000px is large enough.
    //                    - Brian Rowlett, 2020-02-18
    max-width: 1000px !important;
  }

  // Data readout screens

  .craw-section-content, .craw-panel-row {
    display: flex;
  }

  .craw-row-detail {
    .dynamic-panel {
      display: flex;
    }
  }

  // Forms

  .ie-form-grid {
    @extend .ie-craw-explicit-grid;

    > * {
      margin-right: 12px;     // grid-column-gap

      &:last-child {
        margin-right: 0;      // no grid-column-gap for last div
      }
    }
  }

  .craw-form-field {
    @extend .ie-form-grid;
    display: inline-flex;

    .craw-input-label {
      flex: 0 0 196px;
      display: flex;
    }
    .craw-input {
      display: flex;
    }
    .craw-input-operator {
      margin-right: 12px;
      flex: 0 0 180px;
      height: 40px;
    }
    .craw-input-atom {
      flex: auto;
    }

    // This keeps the form fields inline
    @media screen and (max-width: 630px) {
      width: 480px !important;
      .craw-input-atom {
        flex: 0 0 200px !important;
      }
    }
  }

  .craw-datepicker-container {
    @extend .ie-form-grid;
    width: 100%;
    display: inline-flex !important;

    mat-form-field {
      width: calc(50% - 6px);
      &:first-child {
        margin-right: 12px;
      }
    }
  }

  mat-dialog-container {
    z-index: 0;
  }

  app-form-field {
    &:last-of-type > .craw-form-field {
      float: none;
    }
  }

  // CCP-3651: Fix for overflowing texts
  .craw-field-content {
    word-break: break-all;
  }
}

.clickable {
  pointer-events: initial;
  position: absolute;
  left: 0;
  top: 0px;
  width: 90%;
  height: 25px;
}

.clickable-link {
  width:100%
}

.row-action {
  position: absolute;
  left: 0;
  width: 100%;
  height: 45px;
}

.row-action a {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
}

/* 45% with 5% margin on label and field, 50% on container*/
@media only screen and (max-width: 780px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .craw-section-content > div {
    width: 100%;
  }
  .craw-section-content > div {
    margin-right: 0px;
  }
}

@media screen and (max-width: 1120px)  and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .craw-row-detail {
    .craw-panel-row {
      display: block
    }

    .craw-panel-row > * {
      height: auto
    }
  }
}

/*Specific to IE*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-form > .material-icons {
      flex-shrink: 0;
  }
}
//*****  END INTERNET EXPLORER FIX  *******************************//

//********** OLD EDGE FIX *****************************************//
// CCP-3651: Fix for overflowing texts
/* For Edge 12-15 */
@supports (-ms-accelerator:true) {
  .craw-field-content {
    word-break: break-all;
  }
}

/* For Edge 16+ */
@supports (-ms-ime-align:auto) {
  .craw-field-content {
    word-break: break-all;
  }
}
//********** END OLD EDGE FIX *************************************//

//********** BEGIN SMALL SCREEN OVERFLOW FIX (CCP-3927) ***********//
@media screen and (max-width: 720px) {
  .craw-lg-item-row {
    grid-template-columns: 1fr;
  }
}
//********** END SMALL SCREEN OVERFLOW FIX (CCP-3927) *************//

//********** BEGIN CLAIM NOTES BUTTONS ON MOBILE VIEW ***********//
@media screen and (max-width: 630px) {
  app-notes div[table-actions] {
    cds-button {
      .cds-button.-secondary span.cds-button-label {
        display: none;
      }
      .cds-button.-secondary::after {
        content: "";
        font-family: 'Material Icons';
        font-size: 20px;
      }
    }
  }

  app-notes div[table-actions] {
    cds-button:nth-child(1) {
      .cds-button.-secondary::after {
        content: "\e873";
      }
    }
  }

  app-notes div[table-actions] {
    cds-button:nth-child(2) {
      .cds-button.-secondary::after {
        content: "\e2c4";
      }
    }
  }
}
//********** END CLAIM NOTES BUTTONS ON MOBILE VIEW *************//

//********** BEGIN MOBILE VIEW PAGE MARGINS ***********//
@media screen and (max-width: 575.5px) {
  .craw-page-wrap {
    padding: 2.5rem 1rem !important;
  }

  .dashboard .craw-page-wrap {
    padding: 2.25rem 0.5rem !important;
  }

  .dashboard-container {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
//********** END MOBILE VIEW PAGE MARGINS *************//
