@use '@angular/material' as mat;
@import '@angular/material/theming';
$cc-primary-mat: mat.$grey-palette;
$cc-primary: #3A3A3A;
$cc-primary-light: #65686E;
$cc-primary-dark: #36363B;

$cc-background-primary: #40444C;
$cc-background-primary-light: #65686E;
$cc-background-primary-dark: #36363B;

$cc-accent-mat: mat.$lime-palette;
$cc-accent: #aeea00;
$cc-accent-light: #e4ff54;
$cc-accent-dark: #79b700;

$cc-background-accent: #aeea00;
$cc-background-accent-light: #e4ff54;
$cc-background-accent-dark: #79b700;

$cc-warn-mat: mat.$red-palette;
$cc-warn: #c62828;
$cc-warn-light: #ff5f52;
$cc-warn-dark: #8e0000;

$cc-background-warn: #c62828;
$cc-background-warn-light: #ff5f52;
$cc-background-warn-dark: #8e0000;

$cc-util: #979797;
$cc-util-light: #DFDFDF;

$cc-background-util: #979797;
$cc-background-util-light: #DFDFDF;

$primary: mat.define-palette($cc-primary-mat, 600, 400, 900);
$accent:  mat.define-palette($cc-accent-mat, A700, A400);
$warn: mat.define-palette($cc-warn-mat, 700, 600, 900);
$default-theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warn: $warn
    )
));
$background: map-get($default-theme, background);
$foreground: map-get($default-theme, foreground);

$dark-text-color:  rgb(37, 37, 37);

//backgrounds
.cc-background-primary{
    background-color: $cc-background-primary !important;
    color: white !important;
}
.cc-background-primary-light{
    background-color: $cc-background-primary-light !important;
    color: $dark-text-color !important;
}
.cc-background-primary-dark{
    background-color: $cc-background-primary-dark !important;
    color: white !important;
}
.cc-background-accent{
    background-color: $cc-background-accent !important;
    color: white !important;
}
.cc-background-accent-light{
    background-color: $cc-background-accent-light !important;
    color: $dark-text-color !important;
}
.cc-background-accent-dark{
    background-color: $cc-background-accent-dark !important;
    color: white !important;
}
.cc-background-warn{
    background-color: $cc-background-warn !important;
    color: $dark-text-color !important;
}
.cc-background-warn-light{
    background-color: $cc-background-warn-light !important;
    color: $dark-text-color !important;
}
.cc-background-warn-dark{
    background-color: $cc-background-warn-dark !important;
    color: white !important;
}
.cc-background-util{
    background-color: $cc-background-util !important;
    color: white !important;
}
.cc-background-util-light{
    background-color: $cc-background-util-light !important;
    color: black !important;
}

//text color
.cc-text-primary{
    color: $cc-primary !important;
}
.cc-text-primary-light{
    color: $cc-primary-light !important;
}
.cc-text-primary-dark{
    color: $cc-primary-dark !important;
}
.cc-text-accent{
    color: $cc-accent !important;
}
.cc-text-accent-light{
    color: $cc-accent-light !important;
}
.cc-text-accent-dark{
    color: $cc-accent-dark !important;
}
.cc-text-warn{
    color: $cc-warn !important;
}
.cc-text-warn-light{
    color: $cc-warn-light !important;
}
.cc-text-warn-dark{
    color: $cc-warn-dark !important;
}
.cc-text-util{
    color: $cc-util !important;
}
.cc-text-util-light{
    color: $cc-util-light !important;
}

//border color
.cc-border-primary{
    border-color: $cc-primary !important;
}
.cc-border-primary-light{
    border-color: $cc-primary-light !important;
}
.cc-border-primary-dark{
    border-color: $cc-primary-dark !important;
}
.cc-border-accent{
    border-color: $cc-accent !important;
}
.cc-border-accent-light{
    border-color: $cc-accent-light !important;
}
.cc-border-accent-dark{
    border-color: $cc-accent-dark !important;
}
.cc-border-warn{
    border-color: $cc-warn !important;
}
.cc-border-warn-light{
    border-color: $cc-warn-light !important;
}
.cc-border-warn-dark{
    border-color: $cc-warn-dark !important;
}
.cc-border-util{
    border-color: $cc-util !important;
}
.cc-border-util-light{
    border-color: $cc-util-light !important;
}

// Border-top
.cc-border-top-primary {
    border-top-color: $cc-primary !important;
}
